@import '../variables';
@import '../mixins';

#topnav {
	padding: 0;
	margin: 0;
	border: 0;
	ul, li {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	ul {
		position: relative;
		z-index: 100;
		/*float:right;*/
		li {
			font-family:$_STDFont;
			float: left;
			min-height: 1px;
			vertical-align: middle;
			&:hover, &.hover {
				position: relative;
				z-index: 101;
				cursor: default;
			}
			&:hover > ul {
				/*display: block;*/
				visibility: visible;
				opacity: 1;
				transition: 0.5s opacity;
			}
		}
		ul {
			/*display:block;*/
			visibility: hidden;
			opacity: 0;

			position: absolute;
			top: 100%;
			left: 0;
			z-index: 102;
			width: auto;
			li { font-family:$_STDFont; float: none; font-weight: normal; }
			ul {
				top: 0;
				right: auto;
				left: 99.5%;
			}
		}
		ul { bottom: 0; right: 0; }
		ul { margin-top: 0; }
	}
	a {
		font-family:$_STDFont;
		display: block;
		line-height: 1em;
		text-decoration: none;
	}

	/*
		GO MOBILE */
	@include breakWidth(800) {
		ul {
			position: relative;
			li {
				float:none;
				width:100%;
				display:block;
			}
			ul {
				/*display:block;*/
				visibility: visible;
				opacity: 1;

				position: relative;
				top: auto;
				left: 0;
				z-index: 1000;
				width: auto;
				li {}
				ul {
					top: auto;
					right: auto;
					left: 0%;
				}
			}
			ul { bottom: 0; right: 0; }
			ul { margin-top: 0; }
		}
		a {
			display: block;
			line-height: 1em;
		}
	}
}


/*
*	CSS MENU
*	Project specific styles and colours
*/
#topnav {
	position:fixed;
	top:0;
	right:0;
	width:100%;
	background: $WHITE;
	/*border-bottom: 1px solid $THEMECOLOR;*/
	font-family: $_STDFont;
	z-index:100;

	-webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);

	& > ul {
		/**display: inline-block;*/
		& > li > a { line-height: 150%; }
	}
	&:after, ul:after {
		content: '';
		/*display: block;*/
		/*clear: both;*/
	}
	a {
		background: $WHITE;
		color: $COPYGREY;
		padding: 0 2rem;
		font-weight: $bold;
	}
	.home {
		.home-href {
			color:$THEMECOLOR;
		}
	}
	.about {
		.about-href {
			color:$THEMECOLOR;
		}
	}
	.services {
		.services-href {
			color:$THEMECOLOR;
		}
	}
	.contact {
		.contact-href {
			color:$THEMECOLOR;
		}
	}
	ul {
		/*text-transform: uppercase;*/
		li {
			position:relative;
			display:flex;
			flex-direction: column;
			justify-content: flex-end;
			
			text-transform:uppercase;
			font-size: 2rem;
			padding: 0 2rem 2rem 2rem;
			height:13.6rem;
			&.mobile {
				display:none;
			}
			.underline {
				background-color: $THEMECOLOR;
				height:2px;
				width:0%;
			}
			a {
				background-color: $WHITE;
				color: $COPYGREY;
				padding: 10px 0 10px 0;
				font-size: 2rem;
			}
			&:hover > a, &.active > a {
				color: $THEMECOLOR;
				transition: 0.5s color;
			}
			&:hover > .underline:nth-child(2) {
				width:100%;
				transition: 0.5s all;
			}
		}
		ul {
			border-top: 0px solid $LIGHTGREY;
			text-transform: none;
			min-width: 35.5rem;
			a {
				background-color: transparent;
				color: $WHITE;
				border-top: 0 none;
				line-height: 1.2;
				font-size:14px;
				padding: 10px 2rem;
			}
			ul { border-top: 0 none; }
			li {
				width:auto;
				background: $THEMECOLOR;
				position: relative;
				height:auto;
				display:block;
				padding:0 0 0 0;

				&:first-child > a { border-top: 0px solid $LIGHTGREY; }
				&:hover > a {
					color: $WHITE;
					background-color: $COPYGREY;
				}
				&:last-child > a {
					border-bottom: 0px solid $LIGHTGREY;
				}
				&:last-child:hover > a {  }
				&.has-sub {
					/*content: '>';*/
					/*position: absolute;
					top: 50%;
					right: 15px;
					margin-top: -8px;*/
					background-image:url(../images/svg/icon-arrow-right.svg);
					background-size: 3.5rem 3.5rem;
					background-position: right 10px center;
					background-repeat: no-repeat;
				}
			}
		}
	}

	/*
	*
	*/
	.main-cont {
		position:relative;
		display:flex;
		justify-content: space-between;
		flex-direction:row;
		height:13.6rem;
		padding:0 0 0 0;

		.right-cont {
			/*width: pxtopercent(950,1560);*/
			display:inherit;
			height:100%;
			display:flex;
			flex-direction:row;
			align-items: center;
			justify-content:center;
		}
		ul {
			float:none;
			ul {
				/*display:none;*/
			}
		}
	}
	.logo-container {
		width: (pxtopercent(150,1560));
		height:100%;
		display:flex;
		flex-direction:row;
		align-items: center;
		justify-content:center;
		
		box-sizing: border-box;
		img {
			width:100%;
			height:auto;
		}
		p {
			font-family: $_STDFont;
			float:left;
			font-size:35px;
			line-height:72px;
			padding-left: $TEXTPADDING;
			color: $COPYGREY;
			span {
				color: $THEMECOLOR;
			}
		}
	}

	.social-btns-cont {
		height:auto;
		display:flex;
		justify-content: center;
		align-content: center;
		flex-direction: row;
		flex-wrap: nowrap;
		overflow:hidden;

		padding-bottom:10px;

		.icon-cont {
			width:5rem;
			height:5rem;
			position:relative;
			a {
				border: 0px solid $RED;
				width:5rem;
				height:5rem;
				padding:0 0 0 0;
				.circle {
					position:absolute;
					top:50%;
					left:50%;
					margin-left: 0px;
					margin-top: 0px;
					width:0px;
					height:0px;
					border-radius: 2.5rem;
					background-color: $THEMECOLOR;
				}
				.icon {
					position:absolute;
					width:100%;
					height:100%;
					top:0;
					left:0;
					background-size:50% 50%;
					background-position:center center;
					background-repeat:no-repeat;
					&.facebook {
						background-image:url(../images/svg/icon-facebook-blue.svg);
					}
					&.instagram {
						background-image:url(../images/svg/icon-instagram-blue.svg);
					}
					&.linkedin {
						background-image:url(../images/svg/icon-linkedin-blue.svg);
					}
				}
				&:hover > .circle {
					width:5rem;
					height:5rem;
					margin-left: -2.5rem;
					margin-top: -2.5rem;
					transition: 0.5s all;
				}
				&:hover > .icon {
					&.facebook {
						background-image:url(../images/svg/icon-facebook.svg);
						transition: 0.5s all;
					}
					&.instagram {
						background-image:url(../images/svg/icon-instagram.svg);
						transition: 0.5s all;
					}
					&.linkedin {
						background-image:url(../images/svg/icon-linkedin.svg);
						transition: 0.5s all;
					}
				}
			}
		}

		
	}

	.burger-button {
		display:none;
		position:absolute;
		top:20px;
		right:10px;
		line-height:35px;
		height:35px;
		width:35px;
		color: $WHITE;
		padding-left: 0;
		font-size:18px;
		padding-right:10px;
		background-image:url(../images/svg/iconmobile-burger-btn.svg);
		background-color: transparent;
		background-position:right center;
		background-repeat: no-repeat;
		background-size:40px 40px;
		text-transform: uppercase;
		transition:background-image 0.5s;
		&.is-open {
			background-color: transparent;
			background-image:url(../images/svg/iconmobile-burger-x.svg);
			transition:background-image 0.5s;
		}
	}


	/*	
		SHRINK */
	&.shrink {
		/*@include alpha-attribute('background-color', rgba($WHITE,0.7), $BLACK);*/
		ul {
			li {
				height:8rem;
				transition: 0.5s all;
				ul {
					li {
						height:auto;
					}
				}
			}
		}
		.main-cont {
			height:8rem;
			transition: 0.3s all;
			.logo-container {
				width: (pxtopercent(110,1560));
				box-sizing: border-box;
				transition:0.5s all;
				img {
					width:100%;
					height:auto;
					transition:0.5s all;
				}
			}
		}
	}

	/*
		GO MOBILE */
	@include breakWidth(800) {
		position:fixed;
		font-size: 14px;
		overflow-y: auto;
		overflow:visible;
		& > ul {
			& > li > a { line-height: 150%; }
		}
		&:after, ul:after {
			content: '';
		}
		a {
			font-size: $mobileNavSize;
			background: $WHITE;
			color: $COPYGREY;
			padding: 0 $mobileSpace;
			&.desktop {
				display:none;
			}
		}
		.home {
			.home-href {
				color:$WHITE;
			}
		}
		.about {
			.about-href {
				color:$WHITE;
			}
		}
		.services {
			.services-href {
				color:$WHITE;
			}
		}
		.contact {
			.contact-href {
				color:$WHITE;
			}
		}
		ul {
			li {
				position:relative;
				display:block;
				padding: 0 $mobileSpace 0 $mobileSpace;
				height:auto;
				font-weight: $bold;
				.underline {
					display:none;
				}
				&.mobile {
					display:block;
				}
				a {
					background-color: $THEMECOLOR;
					color: $WHITE;
					padding: 15px 0 15px 0;
				}
				&:hover > a, &.active > a {
					color: $THEMECOLOR;
				}
			}
			ul {
				border-top: 0px solid $LIGHTGREY;
				text-transform: none;
				width: 100%;
				display:block;
				a {
					background-color: transparent;
					color: $WHITE;
					border-top: 0 none;
					line-height: 150%;
					font-size:12px;
					padding-left:20px !important;
					/*padding: $mobileSpace $mobileSpace;*/
				}
				ul { }
				li {
					width:100%;
					background: $THEMECOLOR;
					position: relative;
					height:auto;
					padding:0 0 0 0;

					&:first-child > a { border-top: 0px solid $LIGHTGREY; }
					a {
						padding-left:0;
					}
					&.has-sub {
						background-image:url(../images/svg/icon-arrow-right.svg);
						background-size: 3.5rem 3.5rem;
						background-position: right 10px center;
						background-repeat: no-repeat;
					}
				}
			}
		}
		.social-btns-cont {
			display:none;
		}

		
		.main-cont {
			overflow:visible;
			height:9rem;
			.logo-container {
				width: 80px;
				height:100%;
				a {
					padding-left:5px;
				}
				img {
					width:100%;
					height:auto;
				}
			}
			.right-cont {
				position:fixed;
				display:none;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-color: $THEMECOLOR;
				padding-top: 30px;
				z-index:1000;
			}
		}
		.burger-button {
			display:block;
			z-index:1010;
		}

		/*	
			SHRINK */
		&.shrink {
			/*@include alpha-attribute('background-color', rgba($WHITE,0.7), $BLACK);*/
			ul {
				li {
					height:8rem;
					transition: 0.5s all;
					ul {
						li {
							height:auto;
						}
					}
				}
			}
			.main-cont {
				height:8rem;
				transition: 0.3s all;
			}
		}
	}
}
#mobileNav {
	/*display:none;*/
}