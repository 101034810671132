/*@import 'normalize';*/

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import 'variables';
@import 'mixins';
/*@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab');*/
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:100,100i,300,300i,400,700,700i,900|Roboto+Mono|Roboto+Slab');*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:700|Roboto+Condensed:300,300i,400,400i,700&display=swap');
html {
    min-height: 100% !important;
    font-size: 10px;
    @include breakWidth(1920) {
    }
    @include breakWidth(1750) {
		font-size:9.114583333333333px;
	}
	@include breakWidth(1600) {
		font-size:8.33333333333333px;
	}
	@include breakWidth(1440) {
		font-size:8px;
	}
	@include breakWidth(1280) {
		font-size:8px;
	}
	@include breakWidth(1024) {
		font-size:7px;
	}
	@include breakWidth(800) {
		font-size:7px;
	}
	@include breakWidth(740) {
		font-size:7px;
	}
	@include breakWidth(460) {
		font-size:7px;
	}
	@include breakWidth(320) {
	}
	@include breakPrint() {
		font-size:6px;
	}
}

body {
    height:auto;
    min-height:0px !important;
    font-family: $_STDFont;
    margin-top:$headerHeight;
    background-color: $WHITE;
    &.nav-is-open{
	    height:100%;
	    overflow:hidden;
	}
	@include breakWidth(1600) {
		margin-top:100px;
	}
	@include breakWidth(1024) {
		margin-top:9rem;
	}
	@include breakWidth(800) {
		margin-top:9rem;
	}
    @include breakWidth(700) {
		margin-top:9rem;
        padding-bottom:0;
    }
}
p,li,a,span {
	color: $COPYGREY;
	font-size:2rem;
}
p,h3 {
	padding:0 1.5rem 1.5rem 1.5rem;
	line-height:1.5;
	margin:0 0 2rem 0;
}
li {
	line-height:1.5;
}
ul {
	margin-bottom:5rem;
}
h3 {
	font-size: 2.5rem;
	line-height:1.2;
	text-transform: none;
	color:$THEMEORANGE;
	text-transform:uppercase;
}
section.page-section, footer.page-footer {
	position:relative;
	width:100%;
	height:auto;
	margin:0 0 0 0;
	padding:6rem 0 4.5rem 0;
	box-sizing: border-box;
	clear:both;

	background-position:left center;
	background-size:cover;
	background-repeat:no-repeat;
	background-color: $WHITE;

	&:after {
	  content: "";
	  display: table;
	  clear: both;
	}

	header {
		margin:0 2rem 3rem 2rem;
		h1, h2, h3, h4 {
			font-family: $_FANCYFONT;
			text-align:left;
			color: $BLACK;
			margin:0 0 0 0;
			padding:0 0 0 0;
			font-weight:normal;
		}
		h1 {
			font-size:4rem;
		}
		h2 {
			color: $THEMERED;
			font-size:4rem;
			text-align:center;
			text-transform: uppercase;
			font-weight:bold;
		}
		h3 {
		}
		p {
			font-family: $_STDFont;
			text-align:center;
			color: $BLACK;
			margin:0 0 0 0;
			padding:0 0 0 0;
			text-transform: none;
		}
	}

	.href-btn {	
		font-size: 1.6rem;
		font-style: $bold;

		background-color: $THEMERED;
		color: $WHITE;

		height:40px;
		border-radius:20px;
		border:none;

		text-align:center;
		text-decoration: none;
		text-transform: uppercase;
		
		box-sizing: border-box;
		padding:0 4rem 0 4rem;
		margin:0 0 0 0;
	}
	a.href-btn {
		display:flex;
		justify-content: center;
		align-content: center;
		align-items:center;
		text-align:center;
		padding-left:0;
		padding-right:0;
	}

	@include breakWidth(800) {
		.href-btn {	
			font-size: 2rem;
		}
	}


}
.main-cont {
	position:relative;
	width: pxtopercent(1440,1920);
	left:50%;
	margin-left: (pxtopercent(1440,1920) / (-2));
	@include breakWidth(1280) {
		position:relative;
		width: 90%;
		left:5%;
		margin-left:0;
	}
	@include breakWidth(800) {
		left:0;
		width:100%;
		margin-left:0;
		padding-left:2rem;
		padding-right:2rem;
	}
}
section.page-section.page-hero-banner {
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;
	padding:0 0 0 0 !important;
	.banner-overlay {
		width:100%;
		display:block;
	}
	.mobi-banner {
		/*display:none;*/
	}
	.banner-content {
		display: flex;
		align-items: center;
		/*justify-content: center;*/
		text-align: left;

		height:100%;
		margin: auto;
		position: absolute;
		top: 0; left: 1%; bottom: 0; right: 0;
	}
	h1,h2,h3 {
		color: $BLACK;
		font-family: $_STDFontCondensed;
		text-align:left;
		font-weight: $normal;
		text-transform: uppercase;
		strong {
			font-weight:$normal;
		}
	}
	header {
	}
	h1 {
		font-size: 5rem;
		font-weight:$light;
	}
	h2 {
		font-size: 5rem;
	}
	h3 {
		font-size: 4rem;
		text-transform: none;
		color:$THEMEORANGE;
	}
	@include breakWidth(800) {
		h1 {
			font-size:3rem;
		}
	}
	@include breakWidth(460) {
		.banner-overlay {
			/*display:none;*/
		}
		.mobi-banner {
			/*display:block;*/
		}
	}
}

.flexRow {
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.container-shadow {
	-webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
}

/*
	import other components
	*/
@import './components/topnav';
@import './components/footer';
/* -- */