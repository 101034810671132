$BLACK: #000000;
$COPYBLACK: #444444;
$COPYWHITE: #dcdcdc;
$COPYGREY: #7F7F7F;
$LABELGREY: #c4c4c4;
$MAINGREY: #191f24;
$LIGHTGREY: #eaeaea;
$WHITE: #FFFFFF;
$RED: #d40000;


/* project colors */
$THEMECOLOR: #E63531;
$THEMERED: #E63531;
$THEMEORANGE: #F79B4F;
$THEMEGREY: #F5F0F0;

$CGREEN: #65a75b;




$TEXTPADDING: 15px;

$light: 300;
$normal: normal;
$medium: 500;
$bold: bold;
/*
	Fonts
	<link href="https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab" rel="stylesheet">
	@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab');

	font-family: 'Roboto', sans-serif;
	font-family: 'Roboto Condensed', sans-serif;
	font-family: 'Roboto Slab', serif;
	font-family: 'Roboto Mono', monospace;
	*/

$_STDFont: Arial, 'Calibri', Calibri, Helvetica, sans-serif;
$_STDFontBold: Arial, Helvetica, 'Roboto', sans-serif;
$_STDFontCondensed: 'Roboto Condensed', Arial, Helvetica, sans-serif;
$_STDFontSlab: Arial, Helvetica,'Roboto Slab', sans-serif;
$_BTNFont: Arial, 'Calibri', Calibri, Helvetica, sans-serif;

/*$_HFont: 'Bebas Neue', Arial, Helvetica, sans-serif;
$_PFont: 'Calibri',Calibri,Arial,Helvetica, Times, serif;*/

$_FANCYFONT: 'Montserrat', sans-serif;
/*$_FANCYFONT: 'Playfair Display',"Times New Roman", Times, serif;
$_BEBAS: 'Bebas Neue', Arial, Helvetica, sans-serif;
$_CALIBRI: 'Calibri',Calibri,Arial,Helvetica, Times, serif;*/

/* Ethnikids variables */
$PageContentWidth: 1440px;

$secPadding: 30px;
$mobileSpace: 4rem;
$mobilePsize: 14px;
$mobileNavSize: 16px;
$mobileH1size: 25px;
$headerHeight: 130px;