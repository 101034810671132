/**
 * breakpoints for media queries
 * usage example: 
 * .section{
 *   width: 100%;
 *   @include bp($mobile){
 *     width: 50%;
 *   }
 * }
 */

// $smalldesktop:"(max-width: 1279px)";  // fit into 1024px
// $tablet:      "(max-width: 1023px)";  // fit into 720px
// $mobile_wide: "(max-width: 800px)";   // fit into 480px
// $mobile:      "(max-width: 480px)";   // fit into 320px

$mobile: 990; // mobile stuff for Paper Plot

// @mixin bp($size){
//    @media screen and #{$size} { @content; }
// }

@mixin breakWidth($size){
    @media screen and (max-width: #{$size}px){ @content; }
}
@mixin breakHeight($size){
    @media screen and (max-height: #{$size}px){ @content; }
}
@mixin breakPrint() {
    @media print { @content; }
}


@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  
  @return $string;
}

/*
http://thesassway.com/intermediate/mixins-for-semi-transparent-colors
*/
@mixin alpha-background-color($color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  background-color: $solid-color;
  background-color: $color;
}
@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}

/*
  px to percentage
  for that peskey design elements thats floating in a specific point in space
  */
@function pxtopercent($target, $parent: 16) {
  @return percentage($target / $parent);
}
$fullPageWidth: 1920;