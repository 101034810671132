/*
	TOP NAVIGATION STYLES
	*/

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import '../variables';
@import '../mixins';

#page-footer {
	width:100%;
	position: relative;
	background-color: $THEMEORANGE;
	padding-bottom:0;
	.footer-bottom-bar {
		width:100%;
		position: relative;
		background-color: $THEMERED;
		height:50px;
	}
	p,li,a,span {
		font-size:1.6rem;
	}
	.col3-cont {
		display:flex;
		flex-direction:row;
		.col-cont {
			position:relative;
			width:33.3333%;
			box-sizing: border-box;
			padding:0 20px 4rem 0;
			p,li,span {
				font-family: $_STDFont;
				text-align: left;
				color: $WHITE;
				margin:0 0 0 0;
				line-height:1.5;
				a, a:visited, a:active {
					color: $WHITE;
					text-decoration:none;
				}
			}
			i.fab {
				display:inline-block;
				width:3rem;
			}
			p span {
				display:inline-block;
			}
			p span.title {
				width:25%;
			}
			ul {
				margin:0 0 0 0;
				padding:0 0 0 0;
				list-style: none;
				li {
					a {
						/*display:inline-block;*/
						padding:0 0 10px 0;
					}
				}
			}
		}
	}
	


	@include breakWidth(1280) {
		.col3-cont {
			
		}
	}
	@include breakWidth(1024) {
		.col3-cont {
			
		}
	}
	/*
		GO MOBILE
		*/
	@include breakWidth(800) {
		p,li,a,span {
			font-size:2rem;
		}
		.col3-cont {
			flex-wrap:wrap;
			.col-cont {
				position:relative;
				width:50%;
			}
		}
		
	}

	@include breakWidth(460) {

		.col3-cont {
			
		}
		
	}
}


.desktop-split {
	@include breakWidth(800) {
		display:none;
	}
}
.mobile-br {
	display:none;
	@include breakWidth(800) {
		display:inline-block;
	}
}